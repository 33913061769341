import React, { useState } from "react";
import Pass from "../utils/pass";
import "./ContactUs.css";

const ContactUs = () => {
    const [data, setData] = useState({ FullName: "", Email: "", Subject: "", Message: "" });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try{
            const res = await fetch(Pass,{
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                },
                body: JSON.stringify(data),
            });
            window.location.reload();
        }catch(err){
            console.log(err);
        }
    }

    return (
        <div className="contact-container">
            <form className="contact-form">
                <div>
                    <label>Full Name</label>
                    <input
                        type="text"
                        name="FullName"
                        value={data.FullName}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Email</label>
                    <input
                        type="text"
                        name="Email"
                        value={data.Email}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Subject</label>
                    <input
                        type="text"
                        name="Subject"
                        value={data.Subject}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Message</label>
                    <textarea
                        name="Message"
                        value={data.Message}
                        onChange={handleInputChange}
                        style={{ height: "400px" }}
                    />
                </div>
                <div>
                    <button type="submit" onClick={handleSubmit}>Submit</button>
                </div>
            </form>
        </div>
    );
};

export default ContactUs;
