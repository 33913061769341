import React from "react";
import WhatsappIcon from "../images/whatsappicon.png";
import EmailIcon from "../images/emailicon.png";
import MenuIcon from "../images/menuicon.png";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
    const navigate = useNavigate();

    const handleHome = ()=>{
        navigate("/");
      }
    
      const HandleServices = ()=>{
        navigate("/Services");
      }
    
      const handleAboutUs = ()=>{
        navigate("/AboutUs");
      }
    
      const handleContactUs = ()=>{
        navigate("/ContactUs");
      }
  return (
    <div className="footer-container">
        <label>JFA Software House</label>
        <div className="content-container">
            <LazyLoadImage className="icon" src={WhatsappIcon}></LazyLoadImage>
            <ul>
                <li> <a href="tel:+923476379869"> +92 347 6379869</a></li>
                <li> <a href="tel:+923314195778"> +92 331 4195778</a></li>
                <li> <a href="tel:+923203732984"> +92 320 3732984</a></li>
            </ul>
        </div>
        <div className="content-container">
            <LazyLoadImage className="icon" src={MenuIcon}></LazyLoadImage>
            <ul>
                <li onClick={handleHome}> <a> Home</a></li>
                <li onClick={HandleServices}> <a> Services</a></li>
                <li onClick={handleAboutUs}> <a> About Us</a></li>
                <li onClick={handleContactUs}> <a> Contact Us</a></li>
            </ul>
        </div>
        <div className="content-container">
            <LazyLoadImage className="icon" src={EmailIcon}></LazyLoadImage>
            <ul>
                <li> <a href="mail:jfhahouse@gmail.com"> Jfhahouse@gmail.com</a></li>
            </ul>
        </div>
    </div>
  );
};

export default Footer;
