import React from "react";
import WebdevelopmentImg from "../images/webdevelopmentimg.webp";
import DesktopapplicationdevelopmentImg from "../images/desktopapplicationdevelopmentimg.webp";
import InventorymanagementImg from "../images/inventorymanagementimg.webp";
import ProductsellmanagementImg from "../images/productsellmanagementimg.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Services.css";

const Services = ()=>{
    return(
        <div className="services-container">
            <div className="service-container">
                <div className="content">
                    <h1 className="service-heading1"> Web Development</h1>
                    <p className="service-p">At JFA, we specialize in professional and innovative web development solutions tailored 
                        to meet your business needs. Our experienced team offers custom website development, 
                        e-commerce solutions, content management systems, web application development, and 
                        more. With a focus on user experience, SEO optimization, and responsive design, we 
                        create websites that are not only visually appealing but also functional and optimized 
                        for performance. Whether you're looking to launch a new website, upgrade an existing 
                        one, or improve your online presence, we're here to help. Contact us today to discuss 
                        your project and discover how we can take your business to the next level with our web 
                        development services.
                    </p>
                </div>
                <div className="service-image">
                    <LazyLoadImage className="service-img" src={WebdevelopmentImg}  alt="Image Alt" effect="blur"></LazyLoadImage>
                </div>
            </div>

            <div className="service-container">
                <div className="content">
                    <h1 className="service-heading1"> Desktop Application Development</h1>
                    <p className="service-p">At JFA, we specialize in professional and innovative desktop 
                        application development solutions tailored to meet your business needs. Our experienced
                         team offers custom desktop application development, focusing on cross-platform 
                         compatibility, scalability, security, and integration with other systems and 
                         platforms. Whether you're looking to modernize existing desktop software or create a 
                         new application from scratch, we're here to help. Contact us today to discuss your 
                         project and discover how we can elevate your business with our desktop application 
                         development services.
                    </p>
                </div>
                <div className="service-image">
                    <LazyLoadImage className="service-img" src={DesktopapplicationdevelopmentImg} alt="Image Alt" effect="blur" ></LazyLoadImage>
                </div>
            </div>

            <div className="service-container">
                <div className="content">
                    <h1 className="service-heading1"> Inventory Management System</h1>
                    <p className="service-p">At JFA, we specialize in providing comprehensive inventory 
                        management system solutions tailored to meet the unique needs of businesses across 
                        various industries. Our experienced team offers a range of features and functionalities 
                        designed to streamline inventory management processes, optimize stock levels, and 
                        improve overall efficiency. Our inventory management system includes features such as 
                        real-time inventory tracking, barcode scanning, automated reordering, customizable 
                        reporting, and integration with other business systems such as accounting and e-commerce 
                        platforms. With a user-friendly interface and intuitive design, our system empowers 
                        businesses to effectively manage their inventory, reduce costs, and increase productivity. 
                        Whether you're a small startup or a large enterprise, our inventory management system can 
                        be customized to fit your specific requirements and scale with your business as it grows. 
                        Contact us today to learn more about our inventory management system solutions and how we 
                        can help streamline your inventory management processes.
                    </p>
                </div>
                <div className="service-image">
                    <LazyLoadImage className="service-img" src={InventorymanagementImg} alt="Image Alt" effect="blur"></LazyLoadImage>
                </div>
            </div>

            <div className="service-container">
                <div className="content">
                    <h1 className="service-heading1"> Product Sell Management</h1>
                    <p className="service-p">Introducing JFA's comprehensive product sell management solution, 
                        designed to streamline and optimize sales processes for businesses across industries. 
                        Our intuitive platform offers features such as inventory tracking, order processing, 
                        customer relationship management, and sales analytics, empowering you to efficiently 
                        manage sales operations and deliver exceptional customer service. With customizable 
                        workflows and advanced reporting capabilities, you can gain valuable insights into 
                        sales performance and make data-driven decisions to drive growth. Whether you're a 
                        small business or a large enterprise, our flexible solution and dedicated support team 
                        are here to help you succeed. Contact us today to learn more about how our product sell 
                        management solution can revolutionize your sales operations and accelerate your business 
                        growth.
                    </p>
                </div>
                <div className="service-image">
                    <LazyLoadImage className="service-img" src={ProductsellmanagementImg} alt="Image Alt" effect="blur"></LazyLoadImage>
                </div>
            </div>

        </div>
    );

}

export default Services;