import React from "react";
import HomePageGif from "../images/home-page.gif";
import BackgroundImage1 from "../images/qoutebackground.webp";
import BackgroundImage2 from "../images/qoutebackground2.webp";
import BackgroundImage3 from "../images/qoutebackground3.webp";
import BackgroundImage4 from "../images/qoutebackground4.webp";
import "./Home.css";
import { LazyLoadComponent } from "react-lazy-load-image-component";


const Home = ()=>{

    return(
        <>
        <div className="home-container">
            <img src={HomePageGif} className="home-page-gif" loading="lazy"></img>
            <h1 className="value-proposition">A Complete Tech Solution</h1>
            <LazyLoadComponent>
            <div className="message-container" style={{ backgroundImage: `url(${BackgroundImage1})` }} laoding="lazy" >
                <div className="message">
                    <h1 className="quote-head">Innovation</h1>
                    <p className="quote">
                        At JFA, we embrace innovation as our guiding principle. 
                        We strive to push the boundaries of technology and create innovative solutions 
                        that propel our clients to the forefront of their industries. With our 
                        cutting-edge approach and dedicated team, we empower businesses to lead 
                        with innovation and stay ahead of the competition.
                    </p>
                </div>
            </div>
            </LazyLoadComponent>

            <LazyLoadComponent>
            <div className="message-container" style={{ backgroundImage: `url(${BackgroundImage2})` }} laoding="lazy" >
                <div className="message">
                    <h1 className="quote-head">Creativity</h1>
                    <p className="quote">
                        At JFA, we celebrate creativity as the cornerstone of our approach. 
                        We believe that creativity is not only about artistic expression but also about 
                        innovative problem-solving and thinking outside the box. With our team of 
                        creative thinkers and visionary leaders, we bring fresh ideas and innovative 
                        solutions to every project.
                    </p>
                </div>
            </div>
            </LazyLoadComponent>

            <LazyLoadComponent>
            <div className="message-container" style={{ backgroundImage: `url(${BackgroundImage3})` }} laoding="lazy">
                <div className="message">
                    <h1 className="quote-head">Adapt</h1>
                    <p className="quote">
                        At JFA, we thrive on adaptability. We understand that 
                        the technology landscape is constantly evolving, and we embrace change 
                        as an opportunity for growth and innovation. With our adaptable mindset 
                        and flexible approach, we navigate challenges with ease and deliver 
                        solutions that meet the evolving needs of our clients.
                    </p>
                </div>
            </div>
            </LazyLoadComponent>

            <LazyLoadComponent>
            <div className="message-container" style={{ backgroundImage: `url(${BackgroundImage4})` }} laoding="lazy">
                <div className="message">
                    <h1 className="quote-head">Collab</h1>
                    <p className="quote">
                        At JFA, we believe in the power of collaboration. 
                        We foster a collaborative environment where ideas are shared, 
                        creativity flourishes, and teamwork drives success. By working 
                        closely with our clients and partners, we leverage collective 
                        expertise and resources to deliver innovative solutions.
                    </p>
                </div>
            </div>
            </LazyLoadComponent>
        </div>
        </>
    );
}

export default Home;