import React from "react";
import AboutPic from "../images/aboutpic.webp"
import TeamImg from "../images/teamimg.webp";
import CultureandValueImg from "../images/ourvaluesandcultureimg.webp";
import CustomerfocusImg from "../images/customerfocusimg.webp";
import "./AboutUs.css";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";

const AboutUs = ()=>{

    return(
        <div className="about-container">
            <LazyLoadComponent>
            <div className="about-pic" style={{backgroundImage: `url(${AboutPic})`}}>
                <h1>Welcome to JFA Software House</h1>
            </div>
            </LazyLoadComponent>

            <p className="about-p">At JFA, we are dedicated to delivering innovative solutions that empower 
                businesses to thrive in today's competitive landscape. With a focus on excellence and a 
                commitment to customer satisfaction, we provide a range of services designed to meet the 
                diverse needs of our clients. From web development and digital marketing to consulting and 
                beyond, we leverage cutting-edge technology and industry expertise to drive results and exceed 
                expectations. Our mission is simple: to help businesses succeed and grow in a rapidly changing 
                world. With a talented team of professionals and a passion for innovation, we are your trusted 
                partner for all your business needs. 
            </p>

            <div className="card-container">
                <div className="content">
                    <h1 className="service-heading1"> Teams</h1>
                    <p className="service-p">At JFA, our team is comprised of dedicated professionals who are 
                        passionate about delivering exceptional results for our clients. With a diverse 
                        range of expertise and experience, each member of our team brings a unique perspective 
                        and skill set to the table. From our talented developers and designers to our 
                        strategic marketers and consultants, we work collaboratively to tackle 
                        challenges, spark creativity, and drive success. Our team is committed to staying at 
                        the forefront of industry trends and technologies, ensuring that we provide innovative 
                        solutions that meet the evolving needs of our clients. With a focus on excellence, 
                        integrity, and continuous improvement, we are proud to have a team that is not only 
                        highly skilled but also deeply invested in the success of our clients.
                    </p>
                </div>
                <div className="service-image">
                    <LazyLoadImage className="service-img" src={TeamImg}></LazyLoadImage>
                </div>
            </div>

            <div className="card-container">
                <div className="content">
                    <h1 className="service-heading1"> Our Values and Culture</h1>
                    <p className="service-p">Our values and culture at JFA are the foundation upon which we 
                        build our success. We hold ourselves to the highest standards of excellence, striving 
                        to deliver outstanding results in every aspect of our work. Integrity is at the core 
                        of everything we do; we conduct ourselves with honesty, transparency, and 
                        accountability. Innovation is deeply ingrained in our culture, as we continuously 
                        explore new ideas and approaches to drive growth and progress. Collaboration and 
                        teamwork are fundamental to our approach, as we believe that diverse perspectives and 
                        collective effort lead to the best outcomes. Customer focus is paramount, and we are 
                        dedicated to understanding and exceeding the needs of our clients. Additionally, we 
                        are committed to ongoing learning and development, fostering a culture of continuous 
                        improvement where every team member has the opportunity to grow and thrive. Beyond our 
                        business endeavors, we are deeply engaged in our communities, actively contributing to 
                        social and environmental initiatives that make a positive impact. These values define 
                        who we are as a company and guide us in our mission to create value and make a 
                        difference in the world.
                    </p>
                </div>
                <div className="service-image">
                    <LazyLoadImage className="service-img" src={CultureandValueImg}></LazyLoadImage>
                </div>
            </div>

            <div className="card-container">
                <div className="content">
                    <h1 className="service-heading1"> Customer Focus</h1>
                    <p className="service-p">Customer focus is at the heart of everything we do at JFA. We are 
                        dedicated to understanding the needs, preferences, and challenges of our customers, and we 
                        prioritize their satisfaction above all else. From the initial consultation to ongoing support, 
                        we strive to provide personalized attention and tailored solutions that meet and exceed their 
                        expectations. We listen actively to their feedback, anticipate their needs, and consistently 
                        deliver high-quality products, services, and experiences. Our commitment to customer focus 
                        drives us to go above and beyond to build strong, lasting relationships with each and every 
                        client, ensuring their success and satisfaction every step of the way.
                    </p>
                </div>
                <div className="service-image">
                    <LazyLoadImage className="service-img" src={CustomerfocusImg}></LazyLoadImage>
                </div>
            </div>

        </div>
    );
}

export default AboutUs;