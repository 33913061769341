import React, { useState } from 'react';
import './Header.css';
import logo from "../images/companyLogo.png";
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Header = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleHome = ()=>{
    navigate("/");
  }

  const HandleServices = ()=>{
    navigate("/Services");
  }

  const handleAboutUs = ()=>{
    navigate("/AboutUs");
  }

  const handleContactUs = ()=>{
    navigate("/ContactUs");
  }

  return (
    <header className="header">
      <div className="header-left">
        <LazyLoadImage src={logo} alt="Company Logo" className="company-logo" />
        <label className="company-name">Software House</label>
      </div>
      <nav className={showMenu ? "header-right show-menu" : "header-right"}>
        <button className="menu-toggle" onClick={toggleMenu}>Menu</button>
        <ul className="nav-links">
          <li onClick={handleHome}><a >Home</a></li>
          <li onClick={HandleServices}><a>Services</a></li>
          <li onClick={handleAboutUs}><a>About Us</a></li>
          <li onClick={handleContactUs}><a >Contact Us</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;